@import "vars";

*, *::before, *::after {
  box-sizing: border-box;
}
body {
  background-color: #ffffff;
  font-family: 'Arial', sans-serif;
  //color: #000;
}
#root, .container, .main-box {
  height: 100%;
}
.container {
  width: 500px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media all and (min-width: 500px) {
    padding: 20px;
  }
}

.gui-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, .2);
}

.gui-round-btn {
  display: inline-flex;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  padding: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  background-color: $secondary-fill;
  color: $text-secondary;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);
  transition: all .1s;

  &:hover {
    background-color: $secondary-light-fill;
  }

  &:active {
    box-shadow: none;
  }

  &--sm {
    width: 20px;
    height: 20px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
  }
  &--secondary {
    color: $text-primary;
    background-color: $custom-fill;

    &:hover {
      background-color: $custom-light-fill;
    }
  }
}

.main-title {
  margin: 0;
}
.small-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.large-title {
  font-size: 60px;
  font-weight: bold;
  color: $text-highlight;

  &__sub {
    font-size: 30px;
    font-weight: normal;
  }
}

.main-box {
  position: relative;
  border: 5px solid #fff;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .3);
  border-radius: 30px;
  background-color: #fcfcff;

  &__header {
    position: relative;
    padding: 20px 20px 70px 20px;
    background-color: $primary-dark-fill;
    border-radius: 20px 20px 0 0;
    color: $text-primary;
  }

  &__logout {
    position: absolute;
    right: 10px;
    top: 20px;
  }

  &__card {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: -50px;
    height: 100px;
    color: $text-primary;
    background-color: $primary-light-fill;
  }

  &__content {
    padding: 70px 5px 20px 5px;
    height: calc(100% - 130px);
    overflow-y: auto;

    &--default {
      height: 100%;
      padding-top: 20px;
    }
  }

  &__value-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/*MAIN LIST*/
.main-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.main-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    margin-left: 5px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 40px;
    text-align: center;
    margin-right: 5px;
  }
  &__btn {
    margin-left: 20px;
  }
  &__value {
    font-size: 16px;
    white-space: nowrap;
  }
  &--danger {
    background-color: #ffe6e6;
  }

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
  }
}

@media all and (min-width: 375px) {
  .main-list-item__value {
    font-size: 20px;
  }

  .main-box {
    border-width: 15px;

    &__content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .main-box__content {
    padding-left: 10px;
    padding-right: 10px;
  }
}
/*MAIN LIST*/

/*Form Login*/
.c-form {
  &__group {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: $text-info;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
/*Form Login*/

.text-primary {
  color: $text-primary;
}
.text-secondary {
  color: $text-secondary;
}
.text-danger {
  color: $text-danger;
}
.text-highlight {
  color: $text-highlight;
}
.text-info {
  color: $text-info;
}

/* Rules for sizing the icon. */
.gui-icon {}
.gui-icon--xs { font-size: 12px; }
.gui-icon--sm { font-size: 14px; }
.gui-icon--md { font-size: 24px; }
.gui-icon--lg { font-size: 36px; }
.gui-icon--xl { font-size: 48px; }

.gui-text-field {
  display: block;
  width: 100%;
  color: $text-info;
  outline: none;
  background-color: #ffffff;
  border-radius: 3px;
  height: 50px;
  padding: 0 10px;
  font-size: 16px;
  border: 1px solid $custom-light-fill;

  &:focus {
    border-color: $custom-dark-fill;
  }

  &[type="password"] {
    font-size: 20px;
  }
}

.gui-link {
  display: inline-block;
  vertical-align: middle;
  background: none;
  border: 0;
  outline: none;
  text-decoration: none;
  color: $custom-fill;
  font-size: inherit;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.gui-button {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  font-weight: bold;
  border-radius: 10px;
  padding: 15px 25px;
  color: $text-primary;
  background-color: $custom-fill;
  outline: none;
  border: 0;
  cursor: pointer;

  &--block {
    display: block;
    width: 100%;
  }

  &:hover,
  &:focus {
    background-color: $custom-light-fill;
  }

  &:active {
    background-color: $custom-dark-fill;
  }
}

.gui-icon-btn {
  display: inline-block;
  vertical-align: middle;
  background: none;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

/*Utilities*/
.u-ml-5 {
  margin-left: 5px !important;
}
.u-mt-3-neg {
  margin-top: -3px !important;
}
.u-mt-5 {
  margin-top: 5px !important;
}
.u-mt-10 {
  margin-top: 10px !important;
}

.u-text-12 {
  font-size: 12px !important;
}
