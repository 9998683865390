$primary-fill: #212121;
$primary-light-fill: #484848;
$primary-dark-fill: #000000;

$secondary-fill: #ffeb3b;
$secondary-light-fill: #ffff72;
$secondary-dark-fill: #c8b900;

$custom-fill: #5c6bc0;
$custom-light-fill: #8e99f3;
$custom-dark-fill: #26418f;


$text-primary: #ffffff;
$text-secondary: #000000;
$text-danger: red;
$text-highlight: $secondary-fill;
$text-info: $primary-light-fill;
